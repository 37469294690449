

















import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import ChatMessageSearchResultModel from '@/models/crm/chat-message-search-result.model';

@Component({})
export default class CrmChatMessageSearchFormResultItem extends Vue {
  @Prop({ required: true })
  item!: ChatMessageSearchResultModel;

  @Prop({ required: true, default: false })
  isActive!: boolean;

  @Emit('onSelect')
  onSelect(): ChatMessageSearchResultModel {
    return this.item;
  }
}
