

































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import { inject } from 'inversify-props';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import Field from '@/components/field.vue';
import ConversationService from '@/services/crm/conversation.service';
import ChatMessageSearchResultModel from '@/models/crm/chat-message-search-result.model';
import ChatMessageSearchService from '@/services/crm/message-search/chat-message-search.service';
import ConversationModel from '@/models/crm/conversation.model';
import CrmChatHistoryMessagesViewer from '@/components/crm/chat-history-messages-viewer.vue';
import CrmChatMessageSearchFormResultItem from '@/components/crm/chat-message-search-form-result-item.vue';
import NotificationModel from '@/models/notification.model';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';

@Component({
  components: {
    Field,
    CrmChatHistoryMessagesViewer,
    CrmChatMessageSearchFormResultItem,
  },
})
export default class CrmChatMessageSearchForm extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmChatMessageSearchService)
  private messageSearchService!: ChatMessageSearchService;

  @Prop({ default: null })
  openConversation!: ConversationModel | null;

  searchTerm = '';

  isLoading = false;

  showLoadingText = true;

  busyLoader!: LoaderComponent;

  resultList: ChatMessageSearchResultModel[] = [];

  loadedConversation!: ConversationModel | null;

  activeSearchResult!: ChatMessageSearchResultModel | null;

  async mounted(): Promise<void> {
    this.loadedConversation = null;
    this.activeSearchResult = null;

    this.$on('onClearMessageSearch', this.onClear);
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  async onSearch(): Promise<void> {
    this.loadedConversation = null;
    this.activeSearchResult = null;
    this.busyLoader = this.setBusyLoader();
    this.resultList = await this.messageSearchService
      .search(this.searchTerm, this.openConversation?.numeroWhatsapp, this.getClientId());

    this.hideBusyLoader();
    if (this.resultList.length === 0) {
      const notificationText = 'Nenhum registro encontrado para o termo pesquisado!';
      this.$notify.show(new NotificationModel(notificationText, NotificationTypeEnum.Info));
    }
  }

  private getClientId(): string | null {
    if (this.openConversation?.cliente) {
      return this.openConversation.cnpj;
    }

    if (this.openConversation?.prospect) {
      return this.openConversation?.prospect.codProspect;
    }

    return null;
  }

  private onClear(): void {
    this.resultList = [];
    this.isLoading = false;
    this.searchTerm = '';
    this.activeSearchResult = null;
    this.loadedConversation = null;
  }

  private setBusyLoader(showText = true): LoaderComponent {
    this.isLoading = !this.isLoading;
    this.showLoadingText = showText;
    return this.$loading.show({
      container: this.$refs.searchChatHistoryContainer,
      canCancel: false,
    });
  }

  private hideBusyLoader(): void {
    this.isLoading = !this.isLoading;
    this.showLoadingText = true;
    this.busyLoader.hide();
  }

  private isActive(result: ChatMessageSearchResultModel): boolean {
    return this.activeSearchResult?.messageId === result?.messageId;
  }

  private async onClick(result: ChatMessageSearchResultModel) {
    if (this.activeSearchResult !== null && this.activeSearchResult.messageId === result.messageId) {
      return;
    }

    this.busyLoader = this.setBusyLoader(false);
    this.loadedConversation = await this.conversationService.getConversation(result.conversationId, true);
    this.activeSearchResult = result;
    this.hideBusyLoader();
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollToPrimary();
      }, 1300);
    });
  }

  private scrollToPrimary(): void {
    const messageViewer = this.$refs.messageViewerContainer as HTMLElement;
    if (messageViewer) {
      let messageIdSelector = '';
      if (this.activeSearchResult !== null) {
        messageIdSelector = `#message_${this.activeSearchResult?.messageId}`;
      }

      const primaryElement = messageViewer.querySelector(`${messageIdSelector}`);
      if (primaryElement) {
        primaryElement.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    }
  }

  public handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onSearch();
    }
  }
}
