












































































import { Vue, Component, Prop, Emit, VModel } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep } from 'lodash';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ProspectService from '@/services/crm/prospect.service';
import ProspectModel from '@/models/crm/prospect.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';
import CrmExistingContactsView from '@/components/crm/existing-contacts-view.vue';
import ContactModel from '@/models/crm/contact.model';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ContactService from '@/services/crm/contact.service';

@Component({
  components: {
    CrmExistingContactsView,
    Field,
  },
})
export default class CrmChatProspectForm extends Vue {
  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  formIsValid = true;

  model: ProspectModel = plainToClass(ProspectModel, {
    codProspect: null,
    cnpj: null,
    fantasia: null,
    razaoSocial: null,
    contato: null,
    ddi: '55',
    telefone: null,
  });

  telephoneMaskActive = false;

  settings: SettingsModel | null = null;

  @Prop()
  defaultTelephone!: string;

  @VModel()
  hasExistingContacts!: boolean;

  existingContacts: ContactModel[] = [];

  dialogConfig: IKeyValue<IDialogConfig> = {
    existingContacts: {
      show: false,
    },
  };

  telephone = '';

  async created(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    this.rules = {
      cnpj: [[ValidationRules.cnpj, this.settings?.flagTornarCnpjProspectObrigatorio]],
      razaoSocial: [ValidationRules.required],
    };
    if (this.settings.flagTelefoneProspectObrigatorio) {
      this.rules.telefone = [ValidationRules.phone, ValidationRules.required];
    }
  }

  async mounted(): Promise<void> {
    this.model.telefone = this.defaultTelephone;
    this.telephone = this.defaultTelephone;

    setTimeout(() => {
      this.telephoneMaskActive = true;
    }, 150);
  }

  async onVerifyContact(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      this.hasExistingContacts = false;
      try {
        this.existingContacts = await this.contactService.verifyWaIdContacts(
          null,
          this.telephone,
          ClientTypeEnum.Prospect,
          null,
          false,
          true,
        );

        const clientContacts: ContactModel[] = await this.contactService.verifyWaIdContactsClient(
          undefined,
          this.telephone,
          false,
          true,
        );
        clientContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.cnpj === this.model.cnpj || contact.cnpj === c.cnpj)) return;
          this.existingContacts.push(contact);
        });

        const prospectContacts: ContactModel[] = await this.contactService.verifyWaIdContactsProspect(
          undefined,
          this.telephone,
          false,
          true,
        );

        prospectContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.idProspect === contact.idProspect)) return;
          this.existingContacts.push(contact);
        });

        if (this.existingContacts.length === 0) {
          this.onSave();
        } else {
          this.toggleDialogExistingContacts();
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      }
    }
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        const model = cloneDeep(this.model);
        model.cnpj = model.cnpj ? model.cnpj.replace(/\D/g, '') : model.cnpj;
        model.telefone = this.telephone.replace(/\D/g, '');
        const result = await this.prospectService.create(model);

        this.model.codProspect = result.toString();

        this.$notify.success(this.$t('crm.prospectForm.successfullySave'));

        this.$emit('complete', this.model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    } else if (!this.model.telefone && this.settings?.flagTelefoneProspectObrigatorio) {
      this.$notify.warn(this.$t('crm.prospectForm.warn.phoneIsRequired'));
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  rules: IKeyValue<unknown> = {};

  get cnpjCpfMask(): string {
    if (this.model.cnpj) {
      if (this.model.cnpj.length === 17 || (this.model.cnpj.length === 14 && !this.model.cnpj.includes('.'))) {
        return '##.###.###/####-##';
      }
      if (this.model.cnpj.length === 11 || (this.model.cnpj.length === 8 && !this.model.cnpj.includes('.'))) {
        return '###.###.###-##';
      }
    }
    return '';
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.telephone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    if (parsedNumber !== '') {
      return '(##) ####-####';
    }
    return '';
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.prospectFormContainer,
      canCancel: false,
    });
  }

  onCancelSave(): void {
    this.telephone = '';
    this.toggleDialogExistingContacts();
  }

  toggleDialogExistingContacts(): void {
    this.dialogConfig.existingContacts.show = !this.dialogConfig.existingContacts.show;
    this.hasExistingContacts = !this.hasExistingContacts;
  }
}
